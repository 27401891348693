import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Image from "gatsby-image";
import linkedinIcon from "../images/linkedin-white.svg";
import infoIcon from "../images/information.svg";
import {Link} from "gatsby";
import MemberDetails from "../components/member-details";

const IndividualPageLink = ({children, to}) => <Link to={to}>{children}</Link>;

const TeamPage = ({
  data: {
    allStrapiTeams: {nodes},
    defaultAvatar,
  },
}) => {
  return (
    <Layout>
      <SEO title="Team" description="Meet VentureFriends" />
      <div className="py-8">
        <h1 className="text-4xl font-bold">Meet VentureFriends</h1>
      </div>

      {nodes.map((member) => (
        <div className="py-12 border-b-2 border-primary border-solid">
          <MemberDetails
            member={member}
            defaultAvatar={defaultAvatar.nodes[0].fluid}
          />
        </div>
      ))}
    </Layout>
  );
};
export default TeamPage;
export const pageQuery = graphql`
  query getTeams {
    allStrapiTeams(sort: {fields: sortNumber}) {
      nodes {
        slug

        mediumUrl
        twitterUrl
        linkedinUrl
        firstname
        lastname
        designation
        description
        infoUrl
        profilePic {
          childImageSharp {
            fluid(maxWidth: 250) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    defaultAvatar: allImageSharp(
      filter: {fluid: {originalName: {eq: "team-member.png"}}}
    ) {
      nodes {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
