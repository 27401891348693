import React from "react";
import twitterIcon from "../images/twitter.svg";
import linkedinIcon from "../images/linkedin.svg";
import mediumIcon from "../images/medium.svg";

import Img from "gatsby-image";

import ReactMarkdown from "react-markdown";

const NameAndDes = ({name, designation, className}) => (
  <div className={`mb-4 ${className}`}>
    <h2 className="text-primary font-bold text-xl">{name}</h2>
    <span className="font-bold ">{designation}</span>
  </div>
);

const Desc = ({description, className}) => (
  <ReactMarkdown className={`${className} markdown`} source={description} />
);
const MemberDetails = ({
  member,
  noRightBorder = true,
  noLeftMargin = true,
  defaultAvatar,
}) => (
  <div className={`${noLeftMargin ? "" : "md:ml-20"} flex`}>
    <div className="flex flex-col md:mr-12">
      <div
        className={`${
          noRightBorder
            ? ""
            : "border-r-2 md:border-r-0 border-primary border-solid"
        } mb-8`}
      >
        <div className="mb-6">
          {member.profilePic ? (
            <Img
              fluid={member.profilePic.childImageSharp.fluid}
              alt={`${member.firstname} ${member.lastname}`}
              className="w-34 md:w-46"
            />
          ) : (
            <Img
              fluid={defaultAvatar}
              alt={`${member.firstname} ${member.lastname}`}
              className="w-34 md:w-46"
            />
          )}
        </div>
        <NameAndDes
          name={`${member.firstname} ${member.lastname}`}
          designation={member.designation}
          className="md:hidden"
        />
        <span className="flex">
          {member.linkedinUrl && (
            <a href={member.linkedinUrl} target="_blank" className="mr-4">
              <img src={linkedinIcon} alt="linkedin" />
            </a>
          )}
          {member.twitterUrl && (
            <a href={member.twitterUrl} target="_blank" className="mr-4">
              <img src={twitterIcon} alt="linkedin" className="w-8 h-8" />
            </a>
          )}{" "}
          {member.mediumUrl && (
            <a href={member.mediumUrl} target="_blank" className="mr-4">
              <img src={mediumIcon} alt="linkedin" />
            </a>
          )}
        </span>
      </div>
      <Desc className="md:hidden" description={member.description} />
    </div>
    <div className="hidden md:flex md:flex-col ">
      <NameAndDes
        name={`${member.firstname} ${member.lastname}`}
        designation={member.designation}
        className="hidden md:block"
      />
      <Desc className="hidden md:block" description={member.description} />
    </div>
  </div>
);

export default MemberDetails;
